.c-frame {
    $frame: &;

    position: relative;
    border: 0.0625rem solid;
    @include themed(
        'border-color',
        (
            light: v(color-lightgrey),
            dark: v(color-middlegrey),
        )
    );
    margin-top: 0.5rem;

    .c-frame-legend {
        font-size: 0.875rem;
        line-height: 1.15;
        font-weight: 400;
        position: absolute;
        top: -1rem;
        left: 0.5rem;
        padding: 0.5rem;
        margin: 0;
        @include themed(
            'color',
            (
                light: v(color-middlegrey),
                dark: v(color-white),
            )
        );
        @include themed(
            'background',
            (
                light: v(color-white),
                dark: v(color-darkest),
            )
        );
    }

    .c-frame-content {
        padding: 1.2rem 1rem;
    }

    &--no-padding {
        #{$frame}-content {
            padding: 0 !important;
        }
    }

    &--fullwidth {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }

    &--efficiency {
        font-size: 0.875rem;
        line-height: 1.2857142857142858;
        color: v(color-middlegrey);
        order: 3;
        flex: 0 0 calc(35.5% - 1.5rem);
        max-width: calc(35.5% - 1.5rem);
        margin: 1.5rem 0 0 0 !important;
        cursor: default;

        h4 {
            letter-spacing: 0;
            margin: -0.25rem 0 0;

            .svg-icon {
                width: 3.375rem;
                height: auto;
                margin-right: 0.25rem;
            }
        }

        .spinner {
            margin-top: 0 !important;
        }

        p {
            font-size: 1rem;
            line-height: 1.4;
            color: v(color-base);
            margin: -0.125rem 0 0;

            strong {
                font-size: 1.5rem;
                line-height: 1;
                display: block;
            }

            small {
                font-size: 0.75rem;
                display: block;
                padding-top: 0.1875rem;
            }
        }

        #{$frame}-content {
            @include flex(center, flex-start);
            height: 100%;
            padding: 1rem !important;
        }

        &-full {
            max-width: 100% !important;
            flex: 0 0 100% !important;

            #{$frame}-content {
                padding: 1.5rem 1rem !important;
            }

            h4 {
                .svg-icon {
                    margin-right: 1.5rem;
                }
            }

            p {
                @include flex(center, flex-start);
                font-size: 1.5rem;
                font-weight: 700;

                strong {
                    margin-left: 8.25rem;

                    small {
                        font-size: 0.875rem;
                        font-weight: normal;
                    }
                }
            }
        }
    }

    &--live-data {
        .spinner {
            margin: 1rem auto -0.5rem !important;
        }
    }
}
