/*
 * Slick Carousel styles.
 * Kindly stolen from Slick Carousel's repository
 * and adapted to our needs.
 */
.slick {
    &-slider {
        height: 100%;
        position: relative;
        user-select: none;
        touch-action: pan-y;
    }

    &-list {
        overflow: hidden;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    &-track,
    &-list {
        height: 100%;
        transform: translate3d(0, 0, 0);
    }

    &-track {
        &::before,
        &::after {
            display: table;
            content: '';
        }

        &::after {
            clear: both;
        }
    }

    &-slide {
        display: none;
        float: left;
        height: 100%;

        > div {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    &-loading {
        .slick-track,
        .slick-slide {
            visibility: hidden;
        }
    }

    &-initialized .slick-slide {
        display: block;
    }

    &-arrow {
        width: 1.75rem;
        height: 3.25rem;
        display: block;
        position: absolute;
        top: 50%;
        z-index: 10;
        margin-top: -1.625rem;
        cursor: pointer;

        .svg-icon {
            fill: v(color-primary);
            width: 100%;
            height: auto;
            pointer-events: none;
        }
    }

    &-prev {
        left: 0;
    }

    &-next {
        right: 0;
    }

    &-dots {
        font-size: 0;
        text-align: center;
        position: absolute;
        bottom: 2.3125rem;
        right: 0;
        left: 0;
        padding: 0 5rem;
        margin: 0;
        list-style: none outside none;
        pointer-events: none;

        li {
            display: inline-block;
            vertical-align: top;
            padding: 0 0.1875rem;

            &.slick-active {
                button {
                    background-color: v(color-primary);
                }
            }
        }

        button {
            font-size: 0;
            line-height: 0;
            text-indent: 100%;
            white-space: nowrap;
            width: 3rem;
            height: 0.875rem;
            display: block;
            padding: 0;
            border: 0;
            margin: 0;
            overflow: hidden;
            background: v(color-grey);
            border-radius: 0.1875rem;
            box-shadow: none;
            cursor: pointer;
            pointer-events: auto;
            @include app-transition('background-color');

            @include hover {
                background-color: v(color-middlegrey);
            }
        }
    }
}
