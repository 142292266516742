$assets-path: '../../assets' !default;

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@mixin themed(
    $property,
    $properties: ('light', 'dark'),
    $dark-mode-selector: '.dark-mode'
) {
    @if (map-get($properties, light)) {
        #{$property}: map-get($properties, light);
    }

    @if (map-get($properties, dark)) {
        @at-root #{$dark-mode-selector} & {
            #{$property}: map-get($properties, dark);
        }
    }
}

@mixin flex(
    $align: stretch,
    $justify: space-between,
    $wrap: nowrap,
    $direction: row,
    $display: flex
) {
    display: $display;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

@function v($var, $fallback: null) {
    @if ($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

$box-shadow: 0.125rem 0.25rem 1.5rem rgba(0, 0, 0, 0.25);

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if ($index) {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

@function svg-to-data-uri($svg, $fill: '#EA1C0A', $stroke: '#1EA2B1') {
    $svg: str-replace($svg, '#EA1C0A', $fill);
    $svg: str-replace($svg, '#1EA2B1', $stroke);
    $svg: str-replace($svg, '<', '%3C');
    $svg: str-replace($svg, '>', '%3E');
    $svg: str-replace($svg, '"', "'");
    $svg: str-replace($svg, '#', '%23');
    $svg: str-replace($svg, '&', '%26');

    @return url('data:image/svg+xml,#{$svg}');
}

@function svg-url($svg) {
    @if not str-index($svg, xmlns) {
        $svg: str-replace(
            $svg,
            '<svg',
            '<svg xmlns="http://www.w3.org/2000/svg"'
        );
    }

    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);

        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, '%', '%25');
        $chunk: str-replace($chunk, '#', '%23');
        $chunk: str-replace($chunk, '{', '%7B');
        $chunk: str-replace($chunk, '}', '%7D');
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');

        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return url('data:image/svg+xml,#{$encoded}');
}

@mixin background-svg($svg) {
    background-image: svg-url($svg);
}

@function capitalize-color($color) {
    @return to-upper-case(inspect($color));
}

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@keyframes pulse-circle {
    0% {
        opacity: 1;
        transform: scale(0, 0);
    }

    100% {
        opacity: 0;
        transform: scale(1, 1);
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes spin {
    100% {
        transform: rotate(-360deg);
    }
}

@mixin graph-slider-adjusted(
    $slider-height: 9.5rem,
    $thumb-height: 13rem,
    $thumb-offset: -5.5rem,
    $tooltip-offset: -0.75rem
) {
    @media (min-device-width: 48rem) {
        .c-graph-slider {
            height: $slider-height;

            input[type='range'] {
                &::-webkit-slider-thumb {
                    height: $thumb-height;
                    margin-top: $thumb-offset;
                }

                &::-moz-range-thumb {
                    height: $thumb-height;
                    margin-top: $thumb-offset;
                }

                &::-ms-thumb {
                    height: $thumb-height;
                    margin-top: $thumb-offset;
                }
            }
        }

        .c-graph-tooltip {
            top: $tooltip-offset;
        }
    }
}

@mixin container() {
    @include themed(
        'background-color',
        (
            light: v(color-white),
            dark: v(color-darker),
        )
    );
    @include app-border-radius;
    @include app-box-shadow;

    @include mobile {
        @include app-border-radius(0);
    }
}

@mixin app-spacing($type: 'padding', $spacing: 1.5rem) {
    @if ($type == 'both') {
        padding: #{$spacing};
        margin: #{$spacing};
    } @else {
        #{$type}: $spacing;
    }
}

@mixin app-border-radius($radius: 0.5rem) {
    border-radius: $radius;
}

@mixin app-blur($value: 0.1875rem) {
    filter: blur($value);
}

@mixin large-desktop {
    @media (min-width: 95.625rem) {
        @content;
    }
}

@mixin medium-desktop {
    @media (min-width: 90rem) {
        @content;
    }
}

@mixin mobile {
    @media (max-device-width: 47.9375rem) {
        @content;
    }
}

@mixin app-box-shadow($shadow: $box-shadow) {
    box-shadow: $shadow;
}

@mixin mobile-graph($should-adjust: false) {
    @include mobile {
        .c-frame {
            border: 0;

            .c-frame-content {
                position: relative;
                padding: 1rem;
            }
        }

        @if $should-adjust {
            .c-day-picker {
                &,
                &-selects {
                    margin-bottom: 0.625rem;
                }

                &--week {
                    padding-top: 0.375rem;
                    margin-bottom: 0.875rem;
                }
            }

            .c-history-graph {
                &__prev,
                &__next {
                    top: 1rem;
                }

                &__loader {
                    margin: 2.0625rem 0 !important;
                }
            }

            .c-graph-slider input[type='range']::-webkit-slider-thumb {
                height: 10rem;
                margin-top: -4.75rem;
            }
        }
    }
}

@mixin insights-slider-controls {
    .slick-arrow {
        height: 1.75rem;
        margin-top: -0.875rem;

        .svg-icon {
            stroke: v(color-white);
        }

        &.slick-disabled {
            .svg-icon {
                fill: v(color-lightgrey);
                stroke: v(color-grey);
            }
        }
    }

    .slick-prev {
        left: -0.5625rem;

        .svg-icon {
            transform: rotate(180deg);
        }
    }

    .slick-next {
        right: -0.5625rem;
    }

    .slick-slider {
        margin-bottom: 1rem;
    }

    .slick-dots {
        top: 100%;
        bottom: auto;
        padding: 0;
        margin-top: 1rem;

        li {
            padding: 0 0.1875rem;

            &.slick-active {
                button {
                    background: v(color-secondary);
                }
            }
        }

        button {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.125rem;
        }
    }
}

@mixin app-transition($properties, $timing: v(timing), $easing: v(easing)) {
    transition-property: unquote($properties);
    transition-duration: $timing;
    transition-timing-function: $easing;
    will-change: unquote($properties);
}

@mixin app-link($color: v(color-primary)) {
    color: $color;
    font-weight: bold;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}

@mixin app-arrow-link($color: v(color-primary)) {
    color: $color;
    font-weight: 700;
    letter-spacing: -0.01em;
    @include flex(center, flex-start, nowrap, row, inline-flex);
    padding: 0;
    border: 0;
    background: none;
    border-radius: 0;
    box-shadow: none;
    appearance: none;
    cursor: pointer;

    .svg-icon {
        display: block;
        margin-right: 0.25rem;
        stroke: currentColor;
        stroke-width: 0.125rem;

        &:last-child {
            margin-right: 0;
            margin-left: 0.25rem;
        }
    }

    &[disabled] {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
}

@mixin widget-border {
    border-width: 0.0625rem;
    border-style: solid;
    @include themed(
        'border-color',
        (
            light: v(color-lightgrey),
            dark: v(color-middlegrey),
        )
    );
}

@keyframes float {
    0%,
    100% {
        transform: translateY(0rem);
    }

    50% {
        transform: translateY(-0.4rem);
    }
}

.c-tour {
    position: absolute;
    bottom: 10vh;
    left: 0;
    z-index: 86;
    padding: 1rem;

    .c-close {
        top: 0.5rem;
        right: auto;
        left: 0.5rem;
        background: v(color-black);
        border-radius: 50%;
        z-index: 2;

        transform: scale(0.75);

        .svg-icon path {
            stroke: v(color-white);
        }
    }

    &__button {
        font-size: 0.875rem;
        line-height: 1;
        @include themed(
            'color',
            (
                light: v(color-darkgrey),
                dark: v(color-white),
            )
        );
        font-weight: 500;
        position: relative;
        padding: 0;
        border: 0;
        background: none;
        border-radius: 0;
        box-shadow: none;
        appearance: none;
        cursor: pointer;

        &:lang(it) {
            font-size: 0.8125rem;
        }
        .svg-icon {
            margin-left: 0.5rem;

            .astronaut {
                animation: float 8s infinite linear;
            }
        }

        span {
            width: 5.9375rem;
            height: 2.8125rem;
            color: v(color-white);
            @include flex(center, center);
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }
    }

    @include mobile {
        @include flex(center, center, nowrap, row-reverse);
        right: 0;
        bottom: 0.25rem;
        left: auto;
        padding: 0.25rem;

        .c-close {
            position: static;
            margin-left: 0.25rem;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}

.react-joyride__overlay {
    max-height: 100vh;
}
